import { useSidebarContext } from '@local/content-area/dist/Sidebar/SidebarContext';
import { checkIsAdmin } from '@local/login';
import { BreadcrumbSegment } from '@local/web-design-system/dist/components/Breadcrumb';
import { fetchWorkSpaceData } from '@local/workspaces/dist/apiClients/workspaceClientEndpoints';
import { getSelectedWorkspaceFromParams } from '@local/workspaces/dist/components/OrgRouteGuard/OrgRouteGuard';
import { setDocumentTitle } from '@local/workspaces/dist/utils/setDocumentTitle';
import Grid from '@mui/material/Grid';
import { useFlags } from 'launchdarkly-react-client-sdk';
import { useEffect } from 'react';
import { useParams } from 'react-router-dom';

import { PageContent } from 'src/components/pageContent/PageContent';
import { usePersistedState } from 'src/hooks/usePersistedState';
import { AffinityFoldersContent } from 'src/pages/workspacePage/affinityFoldersContent/AffintyFoldersContent';
import { workspaceContentModeSelector } from 'src/store/features/displaySettingSlice';
import { useAppSelector } from 'src/store/store';
import { GEOSCIENCE_OBJECTS } from 'src/strings';
import { workspaceListingWithLinkBreadcrumb } from 'src/utils/breadcrumbs';

import { Sidebar } from './workspaceContent/sidebar/Sidebar';
import { WorkspaceContent } from './workspaceContent/WorkspaceContent';
import { NotFound } from './workspaceNotFound/WorkspaceNotFound';
import { useStyles } from './WorkspacePage.styles';

export const WorkspacePage = ({ tabs }: { tabs: JSX.Element }) => {
    const params = useParams();
    const { classes } = useStyles();
    const [id] = usePersistedState('id');
    const [folderID] = usePersistedState('folderID');
    const { setSidebarContent } = useSidebarContext();

    const fileFolderUUID = id ?? folderID;

    const { evouiAffinityFolders } = useFlags();
    const workspaceContentMode = useAppSelector(workspaceContentModeSelector);
    const isAdmin = checkIsAdmin();

    const { data, isLoading, isError } = fetchWorkSpaceData(params, isAdmin);
    const workspaceId = getSelectedWorkspaceFromParams(params);

    useEffect(() => {
        setSidebarContent(
            <Sidebar
                fileFolderUUID={fileFolderUUID}
                isFile={Boolean(id)}
                workspaceId={workspaceId}
            />,
        );

        return () => {
            // Clear sidebar when leaving the page
            setSidebarContent(undefined);
        };
    }, [fileFolderUUID, workspaceId]);

    if (isError || (!isLoading && !data)) {
        return <NotFound />;
    }

    if (!isLoading) {
        setDocumentTitle(data?.name ?? '');
    }

    const segments: BreadcrumbSegment[] = [
        ...workspaceListingWithLinkBreadcrumb(params),
        { name: data?.name ?? '' },
    ];

    return (
        <Grid container wrap="nowrap" direction="column">
            <PageContent
                breadcrumbSegments={segments}
                breadcrumbsLoading={isLoading}
                pageTitle={data?.name ?? ''}
                pageTitleLoading={isLoading}
                titleActions={tabs}
            >
                <Grid item className={classes.tableHeader} justifyContent="space-between">
                    {GEOSCIENCE_OBJECTS}
                </Grid>
                {evouiAffinityFolders && workspaceContentMode === 'affinity-folders' ? (
                    <AffinityFoldersContent />
                ) : (
                    <WorkspaceContent />
                )}
            </PageContent>
        </Grid>
    );
};
