import { useGetObjectByIdQuery } from '@local/api-clients/dist/goose/enhancedGooseClient';
import { extractObjectSchemaName } from '@local/api-clients/dist/utils/extractObjectSchemaName';
import { trackUserAction } from '@local/metrics/dist/src/metrics';
import { NotFoundSvg } from '@local/svgs/dist/svg/NotFoundSvg';
import { EmptyState } from '@local/web-design-system/dist/components/EmptyState';
import { getOrgUuidFromParams } from '@local/workspaces/dist/components/OrgRouteGuard/OrgRouteGuard';
import { useEffect } from 'react';
import { useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';

import { BaseSidebar } from 'src/components/sidebar/BaseSidebar';
import { usePersistedState } from 'src/hooks/usePersistedState';
import { RootState } from 'src/store/store';
import { OBJECT_NOT_FOUND_MESSAGE, OBJECT_NOT_FOUND_TITLE } from 'src/strings';

import { Categories, UserAction } from '../../../../metrics.types';
import { Empty } from './Empty';
import { Properties } from './Properties';
import { VersionHistory } from './VersionHistory';

interface Props {
    isFile: boolean;
    workspaceId: string;
    fileFolderUUID?: string;
}

export const Sidebar = ({ isFile, workspaceId, fileFolderUUID = undefined }: Props) => {
    const [folderID] = usePersistedState('folderID');
    const params = useParams();

    const filterProperties = useSelector((state: RootState) => state.foldersFilters.foldersFilter);

    const selectedFilter = filterProperties?.find((element) => element.id === folderID);
    const skip = !fileFolderUUID || !isFile;

    const {
        data: gooseData,
        isFetching,
        isError,
    } = useGetObjectByIdQuery(
        {
            objectId: fileFolderUUID ?? '',
            orgId: getOrgUuidFromParams(params),
            workspaceId,
            includeVersions: true,
        },
        {
            skip,
        },
    );

    useEffect(() => {
        if (gooseData) {
            trackUserAction(UserAction.GOOSE_OBJECT_CLICKED, {
                label: `User opened properties on type: ${extractObjectSchemaName(
                    gooseData?.object?.schema,
                )}`,
                category: Categories.WORKSPACE_CONTENT_PAGE,
            });
        }
    }, [gooseData]);

    if (!fileFolderUUID) {
        return <BaseSidebar isFetching={false} properties={<Empty />} versionHistory={null} />;
    }

    if ((isError || (!isFetching && !gooseData)) && isFile) {
        return (
            <div>
                <EmptyState
                    variant="small"
                    image={<NotFoundSvg />}
                    title={OBJECT_NOT_FOUND_TITLE}
                    message={OBJECT_NOT_FOUND_MESSAGE}
                />
            </div>
        );
    }

    return (
        <BaseSidebar
            isFetching={isFetching}
            properties={
                <Properties isFile={isFile} data={gooseData} selectedFilter={selectedFilter} />
            }
            versionHistory={isFile && <VersionHistory data={gooseData} />}
        />
    );
};
