import CloudUploadOutlinedIcon from '@mui/icons-material/CloudUploadOutlined';
import Grid from '@mui/material/Grid/Grid';
import TableCell from '@mui/material/TableCell/TableCell';
import TableRow from '@mui/material/TableRow/TableRow';
import Typography from '@mui/material/Typography/Typography';

import { DRAG_AND_DROP } from 'src/strings';

import { useStyles } from './TableDragAndDrop.styles';

interface Props {
    colSpan: number;
}

export const TableDragAndDrop = ({ colSpan }: Props) => {
    const { classes } = useStyles();

    return (
        <tbody>
            <TableRow className={classes.tableDragAndDrop} automation-id="table-drag-and-drop">
                <TableCell colSpan={colSpan}>
                    <Grid container className={classes.content}>
                        <CloudUploadOutlinedIcon fontSize="large" />
                        <Typography variant="body1" className={classes.text}>
                            {DRAG_AND_DROP}
                        </Typography>
                    </Grid>
                </TableCell>
            </TableRow>
        </tbody>
    );
};
