import { useLazyGetAssociationCollectionQuery } from '@local/api-clients/dist/colormap/enhancedColormapClient';
import { useLazyGetObjectByIdQuery } from '@local/api-clients/dist/goose/enhancedGooseClient';
import { useLazyGetTilesetByObjectIdQuery } from '@local/api-clients/dist/visualization/enhancedVisualizationClient';
import { trackError } from '@local/metrics/dist/src/metrics';
import { useBaseXyz } from '@local/webviz/dist/context';
import type { ListenersArray, MetadataState, UpdateSnapshot } from '@local/webviz/dist/types';
import { UID_SUFFIXES } from '@local/webviz/dist/utilities/uuidGenerators';
import { ElementClass } from '@local/webviz/dist/xyz';
import {
    getOrgUuidFromParams,
    getSelectedWorkspaceFromParams,
} from '@local/workspaces/dist/components/OrgRouteGuard/OrgRouteGuard';
import { useFlags } from 'launchdarkly-react-client-sdk';
import isFinite from 'lodash-es/isFinite';
import merge from 'lodash-es/merge';
import { useEffect } from 'react';
import { useParams } from 'react-router-dom';

import { getAttributeNameColormapsMap } from 'src/store/colormap/selectors';
import { useAppSelector } from 'src/store/store';
import type { TreeStructure } from 'src/store/visualization/visualizationSlice.types';
import { ROOT_TREE_ID } from 'src/strings';
import { createViewSnapshot } from 'src/visualization/context/snapshots/generateSnapshot';
import { Tileset } from 'src/visualization/types';

import { categoryDataSnapshot } from '../snapshots/categoryDataSnapshot';
import { generateColormapSnapshot } from '../snapshots/mappingSnapshot';

export function useObjectLoader(treeItem: TreeStructure) {
    const { getEntityState, setStateFromSnapshot, addViewToPlotDirectly, addListener, getState } =
        useBaseXyz();
    const params = useParams();
    const orgId = getOrgUuidFromParams(params);
    const workspaceId = getSelectedWorkspaceFromParams(params);
    const [getObjectByIdTrigger] = useLazyGetObjectByIdQuery();
    const [getTilesetByTrigger] = useLazyGetTilesetByObjectIdQuery();
    const attributeNameColormapsMap = useAppSelector(getAttributeNameColormapsMap(treeItem.treeId));

    const { evouiEnableColormapsApi } = useFlags();
    const [fetchAssociationCollection] = useLazyGetAssociationCollectionQuery();

    useEffect(() => {
        if (!Object.keys(attributeNameColormapsMap).length) {
            return;
        }
        // creating a snapshot for each colormap in an association
        const snapshot = generateColormapSnapshot(attributeNameColormapsMap, getEntityState);
        if (!Object.keys(snapshot).length) {
            return;
        }
        setStateFromSnapshot(snapshot, {});
    }, [attributeNameColormapsMap]);

    useEffect(() => {
        async function loadAssociationCollection() {
            try {
                await fetchAssociationCollection({
                    orgId,
                    workspaceId,
                    objectId: treeItem.treeId,
                    'api-preview': 'opt-in',
                }).unwrap();
            } catch (error) {
                trackError(
                    `Error loading association collection for objectId: ${treeItem.treeId}`,
                    JSON.stringify(error),
                );
            }
        }
        async function getObjectViewSnapshot() {
            if (treeItem.parentId === ROOT_TREE_ID) {
                if (evouiEnableColormapsApi) {
                    loadAssociationCollection();
                }
                const gooseResponse = await getObjectByIdTrigger(
                    {
                        objectId: treeItem.treeId,
                        orgId,
                        workspaceId,
                    },
                    true,
                ).unwrap();
                return createViewSnapshot(
                    treeItem,
                    gooseResponse,
                    {
                        objectId: gooseResponse.object_id,
                        workspaceId,
                        orgId,
                    },
                    undefined,
                    attributeNameColormapsMap,
                );
            }

            try {
                // assumption that if a parent object exists (i.e. the object is part of a composite), then the parent and tileset are passed to create a snapshot
                // if newly supported composites are rendered differently, a switch case will be needed here

                const parentId = treeItem.parentId.split(':')[0]; // need for GMMs
                const parentGooseResponse = await getObjectByIdTrigger(
                    {
                        objectId: parentId,
                        orgId,
                        workspaceId,
                    },
                    true,
                ).unwrap();

                const tileset: Tileset = (await getTilesetByTrigger(
                    {
                        orgId,
                        workspaceId,
                        objectId: parentId,
                    },
                    true,
                ).unwrap()) as any;

                return createViewSnapshot(
                    treeItem,
                    parentGooseResponse,
                    {
                        objectId: treeItem.parentId,
                        workspaceId,
                        orgId,
                    },
                    tileset,
                );
            } catch (error: any) {
                trackError(error);
                return undefined;
            }
        }

        const listeners: ListenersArray = [];
        getObjectViewSnapshot().then(async (viewSnapshot) => {
            if (!viewSnapshot?.snapshot) return;
            const newSnapshot = viewSnapshot.snapshot;
            if ('status' in newSnapshot) {
                delete newSnapshot.status;
            }
            await setStateFromSnapshot(newSnapshot, {});
            addViewToPlotDirectly(viewSnapshot.viewId);

            Object.keys(viewSnapshot.snapshot).forEach((elementId) => {
                if (!elementId.includes(ElementClass.Tileset3D)) return;
                const removeElementMetadataListener = addListener(
                    elementId,
                    'metadata',
                    async (attributes) => {
                        if (!attributes?.attributes_metadata) return;
                        let { attributes_metadata: attributesMetadata } = attributes;

                        const downloadMetadataRequests: Promise<void>[] = [];
                        const downloadedMetadata: MetadataState = {};
                        const attributeIds = Object.keys(attributesMetadata);
                        attributeIds.forEach((attributeId) => {
                            if ('uri' in attributesMetadata[attributeId]) {
                                const { uri } = attributesMetadata[attributeId];
                                downloadMetadataRequests.push(
                                    fetch(uri)
                                        .then(async (response) => {
                                            if (!response.ok) {
                                                throw new Error(
                                                    `Failed to download attribute ${attributeId} with status code ${response.status}`,
                                                );
                                            }
                                            const metadata = await response.json();
                                            downloadedMetadata[attributeId] = metadata;
                                            delete attributesMetadata[attributeId].uri;
                                        })
                                        .catch((error) => trackError(error)),
                                );
                            }
                        });
                        await Promise.all(downloadMetadataRequests);
                        attributesMetadata = merge(attributesMetadata, downloadedMetadata);
                        const xyzState = getState();
                        const attributesSnapshot = Object.keys(xyzState).reduce(
                            (outerAcc: UpdateSnapshot, entityId) => {
                                const attributeSnapshotPerEntity = attributeIds.reduce(
                                    (innerAcc: UpdateSnapshot, attributeId) => {
                                        if (
                                            !entityId.includes(attributeId) ||
                                            !entityId.includes(`:${UID_SUFFIXES.DATA}`)
                                        )
                                            return innerAcc;
                                        const attributeEntity = getEntityState(entityId);
                                        if (
                                            !attributeEntity ||
                                            !('array' in attributeEntity) ||
                                            !('mapping' in attributeEntity)
                                        ) {
                                            return innerAcc;
                                        }
                                        const arrayEntity = getEntityState(attributeEntity.array);
                                        if (
                                            !arrayEntity ||
                                            !('propertyId' in arrayEntity) ||
                                            !arrayEntity.propertyId
                                        ) {
                                            return innerAcc;
                                        }
                                        const { propertyId } = arrayEntity;
                                        const { metadata } = attributesMetadata[propertyId];
                                        if ('min' in metadata && 'max' in metadata) {
                                            const mappingId = attributeEntity.mapping;
                                            const mappingEntity = getEntityState(mappingId);
                                            if (
                                                mappingEntity &&
                                                'data_control_values' in mappingEntity &&
                                                mappingEntity.data_control_values.every((value) =>
                                                    isFinite(value),
                                                )
                                            ) {
                                                return innerAcc;
                                            }
                                            const { min, max } = metadata;
                                            const minValue = isFinite(min) ? min : -Infinity;
                                            const maxValue = isFinite(max) ? max : +Infinity;
                                            return {
                                                ...innerAcc,
                                                [attributeEntity.mapping]: {
                                                    data_control_values: [
                                                        minValue,
                                                        minValue,
                                                        maxValue,
                                                        maxValue,
                                                    ],
                                                },
                                            };
                                        }
                                        if ('lookup_table' in metadata) {
                                            const { lookup_table: lookupTable } = metadata;

                                            return {
                                                ...innerAcc,
                                                ...categoryDataSnapshot(
                                                    entityId.replace(`:${UID_SUFFIXES.DATA}`, ''),
                                                    lookupTable,
                                                ),
                                            };
                                        }
                                        return innerAcc;
                                    },
                                    {},
                                );
                                return { ...outerAcc, ...attributeSnapshotPerEntity };
                            },
                            {},
                        );
                        if (Object.keys(attributesSnapshot).length) {
                            setStateFromSnapshot(attributesSnapshot, {});
                        }
                    },
                );
                listeners.push(removeElementMetadataListener);
            });
        });
        return () => {
            listeners.forEach((removeListener) => {
                removeListener();
            });
        };
    }, [Object.keys(attributeNameColormapsMap).length]);
}
