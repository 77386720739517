import { TitleRow } from '@local/content-area/dist/TitleRow/TitleRow';
import { Breadcrumbs, Link, useTheme, Skeleton } from '@mui/material';
import classNames from 'classnames';
import { Link as RouterLink } from 'react-router-dom';

import { useStyles } from './PageContent.styles';

export interface PageContentProps {
    breadcrumbSegments: { name: string; path?: string }[];
    breadcrumbsLoading?: boolean;
    pageTitle: string;
    pageTitleLoading?: boolean;
    titleActions?: JSX.Element;
    children: React.ReactNode;
    childrenLoading?: boolean;
    childrenLoadingContent?: JSX.Element;
    pageContentPadding?: boolean;
}

export const PageContent = ({
    breadcrumbSegments,
    breadcrumbsLoading = false,
    children,
    pageTitle,
    pageTitleLoading = false,
    titleActions,
    childrenLoading = false,
    childrenLoadingContent,
    pageContentPadding = true,
}: PageContentProps) => {
    const { classes } = useStyles();
    const theme = useTheme();

    const segments = breadcrumbSegments.map(({ name, path }) => (
        <Link
            component={RouterLink}
            to={path ?? ''}
            sx={{
                textDecoration: 'none',
                color: theme.palette.grey[700],
                fontSize: 12,
                lineHeight: '14px',
                letterSpacing: '0.13px',
            }}
        >
            {name}
        </Link>
    ));

    return (
        <>
            {breadcrumbsLoading ? (
                <Skeleton variant="rectangular" width="200px" height="14px" />
            ) : (
                // TODO temporary breadcrumbs - this should be moving into the header soon:tm:
                <Breadcrumbs>{segments}</Breadcrumbs>
            )}

            <TitleRow title={pageTitle ?? ''} isLoading={pageTitleLoading} actions={titleActions} />
            <div
                className={classNames(classes.container, {
                    [classes.hoveringTable]: pageContentPadding,
                })}
            >
                {childrenLoading && childrenLoadingContent ? childrenLoadingContent : children}
            </div>
        </>
    );
};
