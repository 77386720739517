import { WDSThemeProvider } from '@local/web-design-system-2/dist/theme/WDSThemeProvider';
import { useTrace } from '@local/web-design-system-2/dist/utils/trace';
import { Tabs, Tab, Typography, Stack, Box, Link } from '@mui/material';
import { useFlags } from 'launchdarkly-react-client-sdk';
import { useState } from 'react';

import {
    MACHINE_LEARNING,
    ML_DESC_CONTACT_US,
    ML_DESC_TEXT_1,
    ML_DESC_TEXT_2,
    ML_HEADER_TEXT,
    SETTINGS_TITLE,
} from 'src/strings';

export function AdminSettingsPage() {
    const { evouiWsMlEnablement } = useFlags();
    const [tabSelection, setTabSelection] = useState(MACHINE_LEARNING);
    const handleTabSelection = (_e: React.SyntheticEvent, newValue: string) => {
        setTabSelection(newValue);
    };
    const applyTrace = useTrace('admin-settings');

    return (
        // TODO: Remove Box sx props styling once the base component <ContentScaffolding> is migrated to MUI
        <WDSThemeProvider themeMode="light">
            <Box sx={{ bgcolor: 'background.default', margin: '-24px' }}>
                <Stack
                    direction="row"
                    sx={{
                        justifyContent: 'space-between',
                        alignContent: 'center',
                        p: '32px 32px 16px 32px',
                    }}
                >
                    <Stack>
                        <Typography variant="h3">{SETTINGS_TITLE}</Typography>
                        <Typography variant="h5" color="textSecondary">
                            {tabSelection}
                        </Typography>
                    </Stack>
                    <Box alignContent="center">
                        <Tabs value={tabSelection} onChange={handleTabSelection}>
                            {evouiWsMlEnablement && (
                                <Tab
                                    label={
                                        <Typography
                                            variant="button"
                                            sx={{ textTransform: 'capitalize' }}
                                        >
                                            {MACHINE_LEARNING}
                                        </Typography>
                                    }
                                    value={MACHINE_LEARNING}
                                    automation-id={applyTrace('ml-tab')}
                                />
                            )}
                        </Tabs>
                    </Box>
                </Stack>
                {evouiWsMlEnablement && tabSelection === MACHINE_LEARNING && (
                    <Stack spacing={2} pl={4}>
                        <Typography variant="h5">{ML_HEADER_TEXT}</Typography>
                        <Typography variant="body1">
                            {ML_DESC_TEXT_1}
                            <Link
                                href="https://www.seequent.com/contact-us/"
                                target="_blank"
                                variant="body1"
                                underline="always"
                                color="inherit"
                            >
                                {ML_DESC_CONTACT_US}
                            </Link>
                        </Typography>
                        <Typography variant="body1">{ML_DESC_TEXT_2}</Typography>
                    </Stack>
                )}
            </Box>
        </WDSThemeProvider>
    );
}
