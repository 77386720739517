import { OrgListedObject } from '@local/api-clients/dist/goose/enhancedGooseClient';
import { Delete, Open, Overflow } from '@local/web-design-system-2';
import Divider from '@mui/material/Divider';
import Grid from '@mui/material/Grid';
import IconButton from '@mui/material/IconButton';
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import Typography from '@mui/material/Typography';
import { useFlags } from 'launchdarkly-react-client-sdk';
import { useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';

import { OPEN_IN_DRIVER_ACTION, OPEN_IN_VIEWER_ACTION, RECYCLE_OBJECT_ACTION } from 'src/strings';
import { isSchemaDriverViewable, isSchemaViewable } from 'src/utils/extractSchema';
import { createNavigateToDriver, createNavigateToViewer } from 'src/utils/viewObjectUtils';

import useStyles from './ObjectActions.styles';
import { ListedObjectDisplay } from './utils';

interface ObjectActionProps {
    object: OrgListedObject | ListedObjectDisplay;
    onRecycle: () => void;
}

function RecycleAction() {
    const { classes } = useStyles();
    return (
        <Grid className={classes.objectActionGrid} automation-id="object-row-delete-action">
            <Delete />
            <Typography className={classes.objectActionText}>{RECYCLE_OBJECT_ACTION}</Typography>
        </Grid>
    );
}

function OpenInDriverAction() {
    const { classes } = useStyles();
    // TODO Add Driver Icon
    return (
        <Grid className={classes.objectActionGrid} automation-id="object-row-open-in-driver-action">
            <Open />
            <Typography className={classes.objectActionText}>{OPEN_IN_DRIVER_ACTION}</Typography>
        </Grid>
    );
}

function OpenInViewerAction() {
    const { classes } = useStyles();
    // TODO Add Viewer Icon
    return (
        <Grid className={classes.objectActionGrid} automation-id="object-row-open-in-viewer-action">
            <Open />
            <Typography className={classes.objectActionText}>{OPEN_IN_VIEWER_ACTION}</Typography>
        </Grid>
    );
}

export function ObjectActions({ object, onRecycle }: ObjectActionProps) {
    const featureFlags = useFlags();
    const { evouiEnableDelete, evouiOpenInDriver } = featureFlags;
    const { hostname } = window.location;
    const params = useParams();
    const navigate = useNavigate();
    const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);

    const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
        event.stopPropagation();
        setAnchorEl(event.currentTarget);
    };

    const handleClose = () => {
        setAnchorEl(null);
    };

    const handleRecycle = () => {
        handleClose();
        onRecycle();
    };

    const showViewerOption = isSchemaViewable(object.schema, featureFlags);
    const showDriverOption = evouiOpenInDriver && isSchemaDriverViewable(object.schema);
    const showRecycleOption = evouiEnableDelete;
    const showDivider = (showViewerOption || showDriverOption) && showRecycleOption;

    if (!showViewerOption && !showDriverOption && !showRecycleOption) {
        return null;
    }

    return (
        <>
            <Grid container alignItems="center" justifyContent="center" item xs>
                <IconButton
                    size="large"
                    color="primary"
                    onClick={handleClick}
                    automation-id="overflow-icon"
                >
                    <Overflow fontSize="small" />
                </IconButton>
            </Grid>
            <Menu open={Boolean(anchorEl)} onClose={handleClose} anchorEl={anchorEl}>
                {showViewerOption && (
                    <MenuItem
                        onClick={createNavigateToViewer(
                            navigate,
                            params.hubCode,
                            params.workspaceUuid,
                            object.object_id,
                        )}
                    >
                        <OpenInViewerAction />
                    </MenuItem>
                )}
                {showDriverOption && (
                    <MenuItem
                        onClick={createNavigateToDriver(
                            hostname,
                            params.orgUuid,
                            params.hubCode,
                            params.workspaceUuid,
                            object.object_id,
                        )}
                    >
                        <OpenInDriverAction />
                    </MenuItem>
                )}
                {showDivider && <Divider />}
                {showRecycleOption && (
                    <MenuItem onClick={handleRecycle}>
                        <RecycleAction />
                    </MenuItem>
                )}
            </Menu>
        </>
    );
}
