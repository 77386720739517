import type { AttributeProps } from '@local/webviz/dist/components/Properties/AttributesControl';

import { getGooseObjectById } from 'src/store/goose/selectors';
import { useAppSelector } from 'src/store/store';
import { getTreeItemById } from 'src/store/visualization/selectors';
import { ROOT_TREE_ID } from 'src/strings';
import { extractSchema } from 'src/utils/extractSchema';
import { Schemas } from 'src/visualization/constants';
import type { ObjectAttributeType } from 'src/visualization/DockableDialogs/DockableDialogs.types';
import type {
    DownholeCollectionType,
    Regular2DGridType,
    TriangleMeshType,
} from 'src/visualization/types';
import {
    createAttributeId,
    getGridAttributes,
    getTriangleMeshAttributes,
} from 'src/visualization/utils/snapshotUtils';
import { SUPPORTED_ATTRIBUTE_TYPES } from 'src/visualization/utils/supportedTypes';

export function useSelectedObjectsAttributes(selectedObjectIds: string[]) {
    let attributes: AttributeProps = {};
    const firstSelectedObject = useAppSelector(getGooseObjectById(selectedObjectIds[0]));
    const firstSelectedTreeItem = useAppSelector(getTreeItemById(selectedObjectIds[0]));
    const parentObject = useAppSelector(getGooseObjectById(firstSelectedTreeItem?.parentId ?? ''));

    if (!firstSelectedObject || !firstSelectedTreeItem) {
        return { firstSelectedTreeItem, attributes };
    }

    if (firstSelectedTreeItem.parentId === ROOT_TREE_ID) {
        const itemSchema = extractSchema(firstSelectedObject.object.schema);
        switch (itemSchema) {
            case Schemas.TriangleMeshSchema: {
                attributes = getTriangleMeshAttributes(
                    firstSelectedObject.object as TriangleMeshType,
                ).reduce(reduceAttribute, {});
                break;
            }
            case Schemas.Regular2DGridSchema: {
                // @types/geoscience-object-schemas does not type Attribute Schemas properly
                // name, values and attribute_type are all required on the GO Schemas
                const attrs = getGridAttributes(
                    firstSelectedObject.object as Regular2DGridType,
                ) as ObjectAttributeType[];
                attributes = attrs.reduce(reduceAttribute, {});
                break;
            }
            default: {
                attributes = firstSelectedObject?.object.attributes?.reduce(reduceAttribute, {});
            }
        }
    } else if (parentObject?.object) {
        const { collections } = parentObject.object as DownholeCollectionType;
        const selectedCollection = collections.find(
            (collection) => collection.name === firstSelectedTreeItem?.name,
        );
        attributes = selectedCollection?.from_to?.attributes?.reduce(reduceAttribute, {});
    }

    return { firstSelectedTreeItem, attributes };
}

function reduceAttribute(
    accumulator: AttributeProps,
    { name, values, attribute_type }: ObjectAttributeType,
) {
    if (SUPPORTED_ATTRIBUTE_TYPES.includes(attribute_type)) {
        const propertyId = createAttributeId(values.data);
        const currentAttribute = { [name]: { propertyId, key: `${propertyId}:${name}` } };
        return {
            ...accumulator,
            ...currentAttribute,
        };
    }
    // eslint-disable-next-line no-console
    console.warn(`Unsupported attribute type: ${attribute_type}`);
    return accumulator;
}
