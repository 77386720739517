import DropdownContainer from '@local/filtering/src/Filter/DropdownContainer';
import { trackUserAction } from '@local/metrics';
import { User } from '@local/workspaces/dist/apiClients/GENERATED_workspaceClientEndpoints';
import { useTheme } from '@mui/material';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Divider from '@mui/material/Divider';
import Grid from '@mui/material/Grid';
import Skeleton from '@mui/material/Skeleton';
import Typography from '@mui/material/Typography';
import { DatePicker, LocalizationProvider } from '@mui/x-date-pickers';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFnsV3';
import { useEffect, useState } from 'react';

import { useObjectFilterParams } from 'src/hooks/useObjectFilterParams';
import { Categories, UserAction } from 'src/metrics.types';
import {
    APPLY_FILTERS,
    CLEAR_ALL,
    FILTERS,
    FILTER_OBJECTS,
    FILTER_BY_DATA_TYPE,
    FILTER_BY_UPDATED_BY,
    FILTER_BY_UPDATED_ON,
    SEARCH_FOR_USERS,
    SEARCH_FOR_DATATYPES,
    UNKNOWN_USERNAME,
    SELECT_DATE,
} from 'src/strings';

import { useStyles } from './ObjectFilterMenu.styles';
import { SearchableCheckBoxGroup } from './SearchableCheckBoxGroup';

type DataType = {
    description: string;
    comparison: string[];
};

interface ObjectFilterMenuProps {
    userData: User[];
    isLoadingUsers: boolean;
    typeData: DataType[];
}

const UsersLoading = () => (
    <Grid container gap={2} marginBottom={2}>
        {Array.from({ length: 4 }, (_, index) => index).map((num) => (
            <Skeleton key={num} variant="rectangular" width="48%" height="42px" />
        ))}
    </Grid>
);

export const ObjectFilterMenu = ({ userData, isLoadingUsers, typeData }: ObjectFilterMenuProps) => {
    const { classes } = useStyles();
    const [isOpen, setIsOpen] = useState(false);
    const [startDate, setStartDate] = useState<Date | null>(null);
    const [endDate, setEndDate] = useState<Date | null>(null);
    const theme = useTheme();

    const [, setIsDateRangePickerOpen] = useState(false);

    const {
        applyFilters,
        cannotApplyFilters,
        cannotClearFilters,
        clearAllParams,
        type,
        typeParams,
        updatedBy,
        updatedByParams,
        updatedOnParams,
        setType,
        setUpdatedBy,
        setUpdatedOn,
        reset,
    } = useObjectFilterParams();

    const selectedTypeOptions = typeData.filter((option) => type.includes(option.description));
    const selectedUpdatedByOptions = userData.filter((option) =>
        updatedBy.includes(option.user_id),
    );

    const handleTypeChange = (options: DataType[]) =>
        setType(options.map((option) => option.description));
    const handleUpdateByChange = (options: User[]) =>
        setUpdatedBy(options.map((option) => option.user_id));

    const handleApply = () => {
        applyFilters();
        handleClose();
    };

    const handleStartDateChange = (date: Date | null) => {
        setStartDate(date);
        setUpdatedOn({ startDate: date || undefined, endDate: endDate || undefined });
    };

    const handleEndDateChange = (date: Date | null) => {
        setEndDate(date);
        setUpdatedOn({ startDate: startDate || undefined, endDate: date || undefined });
    };

    const handleOpen = () => {
        setIsOpen(true);
    };

    const handleClose = () => {
        setIsDateRangePickerOpen(false);
        setIsOpen(false);
    };

    useEffect(() => {
        if (!isOpen) {
            return;
        }

        trackUserAction(UserAction.USER_CLICKED_GO_DROPDOWN_FILTER, {
            category: Categories.GO_DROPDOWN_FILTER,
        });
        reset();
    }, [isOpen]);

    const clearAll = () => {
        clearAllParams();
        setTypeSearch('');
        setUpdatedBySearch('');
        setStartDate(null);
        setEndDate(null);
    };

    const isFiltersApplied =
        (updatedOnParams.startDate && updatedOnParams.endDate ? 1 : 0) +
            typeParams.length +
            updatedByParams.length >
        0;

    const [typeSearch, setTypeSearch] = useState('');
    const [updatedBySearch, setUpdatedBySearch] = useState('');
    const disableClearFilters = cannotClearFilters && typeSearch === '' && updatedBySearch === '';
    return (
        <DropdownContainer
            isOpen={isOpen}
            onOpen={handleOpen}
            onClose={handleClose}
            width="450px"
            maxWidth="450px"
            label={isFiltersApplied ? FILTERS : FILTER_OBJECTS}
        >
            <Typography variant="h4" className={classes.typo} automation-id="filter-by-data-type">
                {FILTER_BY_DATA_TYPE}
            </Typography>
            <Divider light={false} className={classes.divider} />
            <SearchableCheckBoxGroup
                placeholder={SEARCH_FOR_DATATYPES}
                options={typeData}
                handleChange={handleTypeChange}
                selectedOptions={selectedTypeOptions}
                getOptionLabel={(option) => option.description}
                getOptionKey={(option) => option.description}
                value={typeSearch}
                setValue={setTypeSearch}
            />

            <Typography variant="h4" className={classes.typo} automation-id="filter-by-updated-on">
                {FILTER_BY_UPDATED_ON}
            </Typography>
            <Divider light={false} className={classes.divider} />
            <Box columnGap={theme.spacing(2)} sx={{ mb: 2, display: 'flex', alignItems: 'center' }}>
                <LocalizationProvider dateAdapter={AdapterDateFns}>
                    <DatePicker
                        label={SELECT_DATE}
                        value={startDate || null}
                        onChange={handleStartDateChange}
                        maxDate={endDate || undefined}
                        slotProps={{
                            textField: {
                                inputProps: {
                                    'automation-id': 'start-date-picker',
                                },
                            },
                        }}
                    />
                    <DatePicker
                        label={SELECT_DATE}
                        value={endDate || null}
                        onChange={handleEndDateChange}
                        minDate={startDate || undefined}
                        slotProps={{
                            textField: {
                                inputProps: {
                                    'automation-id': 'end-date-picker',
                                },
                            },
                        }}
                    />
                </LocalizationProvider>
            </Box>
            <Typography variant="h4" className={classes.typo} automation-id="filter-by-updated-by">
                {FILTER_BY_UPDATED_BY}
            </Typography>
            <Divider light={false} className={classes.divider} />
            {isLoadingUsers ? (
                <UsersLoading />
            ) : (
                <SearchableCheckBoxGroup
                    placeholder={SEARCH_FOR_USERS}
                    options={userData}
                    handleChange={handleUpdateByChange}
                    selectedOptions={selectedUpdatedByOptions}
                    getOptionLabel={(option) => option.full_name || UNKNOWN_USERNAME}
                    getOptionKey={(option) => option.user_id}
                    value={updatedBySearch}
                    setValue={setUpdatedBySearch}
                />
            )}

            <Divider light className={classes.thickDivider} />
            <Grid className={classes.footerContainer}>
                <Button
                    className={classes.footerButton}
                    size="large"
                    onClick={clearAll}
                    disabled={disableClearFilters}
                    color="secondary"
                    variant="contained"
                    automation-id="go-dropdown-filter-clear-all-button"
                >
                    <Typography className={classes.footerButtonText}>{CLEAR_ALL}</Typography>
                </Button>
                <Button
                    className={classes.footerButton}
                    size="large"
                    color="primary"
                    variant="contained"
                    onClick={handleApply}
                    disabled={cannotApplyFilters}
                    automation-id="go-dropdown-filter-apply-button"
                >
                    <Typography className={classes.footerButtonText}>{APPLY_FILTERS}</Typography>
                </Button>
            </Grid>
        </DropdownContainer>
    );
};
