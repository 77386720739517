import { GeoscienceObjectVersion } from '@local/api-clients/dist/goose/enhancedGooseClient';
import { useMessagesContext } from '@local/messages-wds2/dist/MessagesContext';
import { Copy, Overflow } from '@local/web-design-system-2';
import Grid from '@mui/material/Grid/Grid';
import IconButton from '@mui/material/IconButton';
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import Typography from '@mui/material/Typography/Typography';
import { useState } from 'react';

import { COPIED_TO_CLIPBOARD, COPY } from 'src/strings';

import { useStyles } from './VersionActions.styles';

const CopyAction = () => {
    const { classes } = useStyles();

    return (
        <Grid container justifyContent="flex-start" alignItems="center">
            <Copy />
            <Typography className={classes.actionText}>{COPY}</Typography>
        </Grid>
    );
};

interface Props {
    version: GeoscienceObjectVersion;
}

export const VersionActions = ({ version }: Props) => {
    const { addMessage } = useMessagesContext();
    const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);

    const handleCopy = () => {
        navigator.clipboard.writeText(version.version_id);
        addMessage({
            message: COPIED_TO_CLIPBOARD,
            severity: 'success',
        });
    };

    const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
        event.stopPropagation();
        setAnchorEl(event.currentTarget);
    };

    const handleClose = () => {
        setAnchorEl(null);
    };

    return (
        <>
            <Grid container alignItems="center" justifyContent="center" item xs>
                <IconButton
                    size="large"
                    color="primary"
                    onClick={handleClick}
                    automation-id="overflow-icon"
                >
                    <Overflow fontSize="small" />
                </IconButton>
            </Grid>
            <Menu anchorEl={anchorEl} onClose={handleClose} open={Boolean(anchorEl)}>
                <MenuItem onClick={handleCopy} automation-id="menu-menu-item-copy-object-id">
                    <CopyAction />
                </MenuItem>
            </Menu>
        </>
    );
};
