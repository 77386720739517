import type { Folder } from '@local/workspaces/dist/apiClients/workspaceClientEndpoints';

import { Header } from 'src/components/sidebar/Header';
import { useStyles } from 'src/components/sidebar/Properties.styles';
import { PropertyHeader } from 'src/components/sidebar/PropertyHeader';
import { PropertyText } from 'src/components/sidebar/PropertyText';
import { FILTER_DESCRIPTION, FILTER_NAME, FILTER_PROPERTIES } from 'src/strings';

interface Props {
    data?: Folder;
}

export const FilterProperties = ({ data }: Props) => {
    const { classes } = useStyles();

    return (
        <>
            <Header text={FILTER_PROPERTIES} />
            <div className={classes.objectProperties}>
                <PropertyHeader text={FILTER_NAME} />
                <PropertyText text={data?.name} />
            </div>
            <div className={classes.objectProperties}>
                <PropertyHeader text={FILTER_DESCRIPTION} />
                <PropertyText text={data?.description} />
            </div>
        </>
    );
};
